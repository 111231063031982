import styled from 'styled-components';

export const HeaderContainer = styled.div`
    height: 70px;
    width: 100%;
    background-color: #000000;

    position: fixed;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;

    > .sit-svg {
        height: 80%;
        cursor: pointer;
        > div {
            height: 100%;
            > svg {
                height: 100%;
            }
        }
    }

    > .menu-container {
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 100%;
        cursor: pointer;
        
        > .menu-svg {
            height: 50%;
            > div {
                height: 100%;
                > svg {
                    height: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 40px;

        > .menu-container {
            column-gap: 10px;
            > p {
                font-size: 1.5em;
            }
        }
    }
`;