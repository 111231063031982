import styled from 'styled-components';

export const ModalHeaderNavContainer = styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    background-color: #000000;
`;

export const HeaderContainer = styled.div`
    width: 100%;
    height: 70px;
    padding: 0 5%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0;

    > .sit-svg {
        height: 80%;
        cursor: pointer;
        > div {
            height: 100%;
            > svg {
                height: 100%;
            }
        }
    }

    > .menu-container {
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 100%;
        cursor: pointer;

        > .fechar-svg {
            height: 50%;
            > div {
                height: 100%;
                > svg {
                    height: 100%;
                }
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        height: 40px;

        > .menu-container {
            column-gap: 10px;

            > p {
                font-size: 1.5em;
            }
        }
    }
`;

export const BodyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    height: 100%;

    > .circulos {
        svg {
            height: 200px;
        }
    }

    > .contato {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        text-align: left;
    
        > h1 {
            color: #D7B13B;
            font-weight: 100;
            font-size: 5em;
            line-height: 30px;
            font-style: italic;
        }
    
        > h2 {
            color: #D9000D;
            font-weight: 300;
            font-size: 3em;
            font-style: italic;
        }
    
        > p {
            padding: 10px;
            width: 280px;
        }
    
        > div {
            display: flex;
            column-gap: 15px;
            padding: 0 10px;

            > a {
                width: 50px;
                svg {
                    width: 100%;
                    cursor: pointer;
                    /* :hover {
                        path, polygon, rect, circle, ellipse {
                            fill: #EFBF2D;
                        }  
                    } */
                }
            }
        }
    }

    > nav {
        display: flex;
        flex-direction: column;
        width: 45%;

        > a,
        > a:link,
        > a:active,
        > a:visited {
            text-align: right;
            font-size: 4.5em;
            font-weight: 100;
            text-decoration: none;
            font-style: italic;
            padding-right: 15px;
        }
    
        > a:hover {
            font-weight: 400;
            border-right: 6px solid #d51b17;
        }
    }


    @media screen and (max-width: 1210px) {
        > nav {
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 4em;
            }
        }
    }

    @media screen and (max-width: 1090px) {
        > nav {
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 3.5em;
            }
        }
    }

    @media screen and (max-width: 950px) {
        > nav {
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 3em;
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        > .circulos {
            display: none;
        }
        > .contato {
            display: none;
        }

        > nav {
            width: 90%;
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 7em;
                font-weight: 400;
                margin: 5px 0;
            }
        }
    }

    @media screen and (max-width: 500px) {
        > nav {
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 6em;
            }
        }
    }

    @media screen and (max-width: 400px) {
        > nav {
            > a,
            > a:link,
            > a:active,
            > a:visited {
                font-size: 5em;
            }
        }
    }
`;