import styled from 'styled-components';

export const HomeContainer = styled.div`
    > .loading {
        background: #fff;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
`;

export const HomeTopoContainer = styled.div`
    background-image: url('https://istockblobs.blob.core.windows.net/sitesittrade/fundo-inicio.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    height: 100vh;
    padding: 50px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        height: 100%;
    
        svg {
            height: 200px;
        }

        > .frase-container {
            > :first-child {
                font-size: 6em;
                font-weight: 100;
                font-style: italic;
                color: #D4A026;
                line-height: 65px;
            }
        
            > :nth-child(2) {
                font-size: 6em;
                font-weight: 100;
                font-style: italic;
                margin-left: 67px;
                line-height: 55px;
            }
        
            > :nth-child(3) {
                font-size: 6em;
                font-weight: 100;
                font-style: italic;
                color: #D4A026;
                margin-left: 30px;
                line-height: 70px;
            }
        
            > :nth-child(3) > strong {
                font-size: 1em;
                font-weight: 100;
                font-style: italic;
                color: #D9000D;
            }
        
            > :last-child {
                font-size: 6em;
                font-weight: 100;
                font-style: italic;
                color: #D9000D;
                margin-left: 170px;
                line-height: 90px;
            }
        }
    }

    > .saiba-mais-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        font-style: italic;
        text-decoration: none;
        cursor: pointer;

        > p > b {
            font-weight: 500;
        }
    
        svg {
            height: 20px;
        }
    }


    @media screen and (max-width: 768px) {
        > .body {
            svg {
                height: 60px;
            }

            > .frase-container {
                > :first-child {
                    font-size: 4em;
                    line-height: 20px;
                }
        
                > :nth-child(2) {
                    font-size: 4em;
                    line-height: 20px;
                    margin-left: 23px;
                }
        
                > :nth-child(3) {
                    font-size: 4em;
                    line-height: 20px;
                    margin-left: 5px;
                }
        
                > :nth-child(3) > strong {
                    font-size: 1em;
                }
        
                > :last-child {
                    font-size: 4em;
                    margin-left: 45px;
                    line-height: 30px;
                }
            }
        }

        > .saiba-mais-container {
            row-gap: 5px;

            > p {
                font-size: 1.2em;

                > b {
                    font-size: 1em;
                }
            }
    
            svg {
                height: 20px;
            }
        }

    }
`;

export const QuemSomosContainer = styled.div`
    > .description-container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15%;
        margin: 80px 10% 0;
        padding: 80px 40px;

        svg {
            height: 200px;
        }
    
        > h1 {
            font-size: 6em;
            font-weight: 300;
            font-style: italic;
            color: #000;
            display: flex;
            flex-direction: column;
            line-height: 70px;

            > span{
                font-size: 1em;
                color: #D9000D;
            }
        }
    
        > p {
            color: #000;

            > b{
                font-size: 1em;
                color: #000;
            }
        }
    
    }


    > p {
        color: #000;
        text-align: center;
        font-style: italic;
        margin: 40px 0 80px;

        > b{
            color: #000;    
        }
    }

    > .images-container {
        display: flex;
        justify-content: space-between;
        column-gap: 4px;

        width: 100%;
    
        > div {
            width: 20%;
            > img {
                width: 100%;
            }
        }
    
    }

    @media screen and (max-width: 768px) {
        > .description-container {
            column-gap: 5%;
            margin: 20px 5% 0;
            padding: 50px 15px;
    
            svg {
                height: 60px;
            }

            > h1 {
                font-size: 5em;
                line-height: 30px;
            }
    
            > h2 {
                font-size: 3em;
                line-height: 18px;
            }
        }

        > p {
            margin: 10px 0 30px;
        }

    }
`;

export const OqueFazemosContainer = styled.div`
    padding: 80px 0;

    > .oque-fazemos-container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10%;
        margin: 50px 10%;

        svg {
            height: 200px;
        }

        > h1 {
            display: flex;
            flex-direction: column;
    
            font-size: 6em;
            font-weight: 300;
            font-style: italic;
            line-height: 70px;
            color: #000;

            > span {
                color: #D51B17;
                font-size: 1em;
            }
        }
    
        > p {
            color: #000;
            max-width: 500px;
            
            > b {
                color: #000;
            }
        }
    }



    > .itens-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10%;
        width: 70%;
        margin: 0 auto;

        > .item {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            > h2 {
                font-size: 2em;
                font-weight: 500;
                font-style: italic;
                color: #000;
            }
        
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
            
                svg {
                    width: 60px;
                }
            
                > p {
                    color: #000;
                }
            
                > p > a,
                > p > a:link,
                > p > a:active,
                > p > a:visited {
                    color: #000;
                }
            }
        }
    }

    > .map-container {
        background-color: #000;  
        position: relative;
        z-index: -1;
        height: 400px;
        margin-top: 150px;
    
        svg {
            height: 120%;
            position: absolute;
            width: 500px;
            top: -10%;
            left: 10%;
        }
    
    
        > section {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 10%;
            width: 40%;
            height: min-content;
    
            > h1 {
                font-size: 2.5em;
                font-weight: 300;
                margin-bottom: 10px;
                > b {
                    font-size: 1em;
                    font-weight: 300;
                    color: #D51B17;

                    > span{
                        font-size: 1em;
                        font-weight: 300;
                        color: #EFBF2D;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1240px) {
        > .map-container {
            svg {
                left: 2%;
            }
        }
    }

    @media screen and (max-width: 1010px) {
        > .map-container {
            svg {
                width: 400px;
            }
        }

        > .oque-fazemos-container {
            svg {
                height: 140px;
            }
    
            > h1 {
                font-size: 4em;
            }
        }

        > .itens-container {
    
            > .item {
                > h2 {
                    font-size: 1.4em;
                }
        
                > div {
                    svg {
                        width: 25px;
                    }
            
                    > p {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 860px) {
        > .map-container {
            svg {
                width: 300px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 20px 0;

        > .oque-fazemos-container {
            margin: 30px 5%;
            column-gap: 5%;
            svg {
                height: 60px;
            }
    
            > h1 {
                font-size: 3em;
                line-height: 18px;
            }
        }


        > .itens-container {
            gap: 5%;
            width: 90%;
            max-width: 350px;
    
            > .item {
                > h2 {
                    font-size: 1em;
                }
        
                > div {
                    svg {
                        width: 25px;
                    }
            
                    > p {
                        font-size: 0.8em;
                    }
                }
            }
        }

        > .map-container {
            margin-top: 50px;
            height: 200px;

            svg {
                height: 70%;
                top: 10%;
                left: 5%;
            }
    
            > section > h1 {
                font-size: 1.5em;
            }
        }
    }

    @media screen and (max-width: 600px) {
        > .map-container {
            svg {
                width: 150px;
            }
        }
    }
`;

export const CasesContainer = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    > h1 {
        text-align: center;
        font-size: 6em;
        font-style: italic;
        font-weight: 300;
        color: #D51B17;
    }

    > p {
        text-align: center;
        color: #000;
        font-size: 1.2em;

        margin: 30px 0 5px;
        > b {
            color: #000;
            font-size: 1em;
        }
    }
    
    > a,
    > a:visited,
    > a:active,
    > a:link {
        color: #000;
        text-align: center;
        margin: 0 auto;
        font-size: 1.2em;
    }

    > .cases {
        display: flex;
        align-items: center;
        column-gap: 5%;
        margin: 40px 10%;
    
        > .images-container {
            display: grid;
            grid-template-areas: 
            'guarana mikes hoegaarden pepsi'
            'colorado mikes vivant beats'
            'mikesk isla bud beats';
            gap: 4px;
            width: 100%;
            > div {
                width: 100%;
                height: 100%;
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
    }

    @media screen and (max-width: 768px){
        padding: 20px 0;

        > h1 {
            font-size: 3em;    
        }

        > p {
            margin: 20px 0;
        }

        > .cases {
            margin: 15px 5%;
    
            svg {                
                height: 60px;
                margin: auto 0;
            }

            > .images-container {
                gap: 2px;
            }
        }


        > h2 {
            font-size: 2em;
        }

        > a,
        > a:visited,
        > a:active,
        > a:link {
            font-size: 1em;
        }
    }
`;

export const ClientesContainer = styled.div`
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    column-gap: 10%;

    > div > div > svg {
        height: 200px;
    }

    > h1 {
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-size: 3em;

        > b {
            color: #EFBF2D;
            font-weight: 300;
            font-size: 1em;
        }
    }

    > .carousel {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 440px;
        height: 200px;

        > .logos-container {
            overflow: hidden;
            width: 100%;
            height: 150px;
        
            > .images-container {
                display: flex;
                height: 100%;
                width: min-content;
                justify-content: space-between;
                align-items: center;
                transition: margin 0.5s linear;
                margin-left: ${({carouselPage}) => {
                    switch (carouselPage) {
                        case 1:
                            return '-20px';
                        case 2:
                            return '-500px';
                        case 3:
                            return '-980px';
                        case 4:
                            return '-1460px';
                        default:
                            return '-20px';
                    }
                }};

                > div {
                    width: 120px;
                    margin: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > img {
                        width: 100%;
                    }
                }
            
            }
        }
    
        > .buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 4px;
    
            width: 100%;
        }
    
        > .buttons-container > div {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            cursor: pointer;
        }
    }


    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 30px 0;

        > div > div > svg {
            display: none;
        }

        > h1 {
            font-size: 2em;
        }

        > .carousel {
            width: 240px;
            height: 100px;
            margin-top: 20px;
    
            > .logos-container {
                height: 60px;

                > .images-container {
                    left: ${({carouselPage}) => {
                        switch (carouselPage) {
                            case 1:
                                return '0';
                            case 2:
                                return '-240px';
                            case 3:
                                return '-480px';
                            case 4:
                                return '-720px';
                            default:
                                return '0';
                        }
                    }};

                    > div {
                        width: 60px;
                        margin: 0 10px;
                    }
                }
        
            }
    
            > .buttons-container > button {
                width: 10px;
                height: 10px;
                border-style: none;
            }
        }
    }
`;