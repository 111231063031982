import styled from "styled-components";

export const NossasSolucoesPageStyle = styled.div`
    h1{
        color: blue
    }

    a{
        text-decoration: none;
    }

    .redWhite{
        display: flex;
        justify-content: center;
        width: 100%; 
        height: 622px; 
        background: linear-gradient(to bottom, #D81020 52%, white 52%);
    }

    .magic{
        margin-top: 33px;
        margin-left: 20px;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 0px 0px 0px 30px;

        img{
            position: absolute;
            width: 737px;
            /* width: 674px; */
        }
    }

    .ancoraSit{
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 9999;
        cursor: pointer;
        margin-top: 26px;


        .clickBranco{
            svg{
                width: 35px;
                height: 35px;
                margin-left: 5px;
            }
        }

        h2{
            color: white;
            font-size: 13px;
            font-weight: 500;
            display: flex;
            margin-left: 20px;
        }
    }

    .divMid{
        margin-top: 291.3px;
        width:260px;
        height: max-content;
        padding: 10px 10px 10px 19px;
        margin-right: auto;
        border-radius: 0px 300px 300px 0px;
        background-color: white;
        z-index: 9999;
        position: absolute;

        h2{
            color: #D81020;
            font-size: 14px;
            font-weight: 400;
            display: flex;
        }

        h3{
            color: #D81020;
            font-weight: 600;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    .logoSit{
        position: absolute;
        width: 100px;
        margin-top: 80px;
        margin-left: 20px;
         svg{
            width: 150px;
            height: 130px;
         }
    }

    .textoLogoSit{
        position: absolute;
        color: white;
        font-size: 12px;
        font-weight: 300;
        margin-top: 230px;
        margin-left: 20px;
        width: 150px;
        letter-spacing: 0.6px;
        line-height: 1.5;
    }

    .linhaBranca{
        position: absolute;
        width: 6px;
        height: 180px;
        background-color: white;
        margin-top: 315px;
        margin-left: 12px;
        z-index: 9999;
        border-radius: 50px;
    }

    .lista{
        position: absolute;
        margin-top: 368px;
        margin-left: 30px;
        z-index: 9999;

        h2{
            color: white;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            font-weight: 500;
        }

        h3{
            color: white;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            font-weight: 300;
            width: 200px;
        }
    }

    .red{
        display: flex;
        justify-content: center;
        flex-direction:column ;
        position: absolute;
        z-index: 9999;
        bottom: 8px;
        right: 20px;
        padding: 9px  18px 0px;
        border-radius: 90px;
        background-color: #D81020;

        h4{
            color: white;
            font-size: 11px;
            font-weight: 500;
            display: flex;
        }

        svg{
            width: 65px;
            height: 60px;      
        }
    }

    .maos{
        display: flex;
        justify-content: center;
    }

    .ifollow{
        background-color: #783C94;
        margin-top: 40px;
        width: 100%;
        padding: 30px 0px 50px;
        position: relative;
    }

    .ancoraIfollow{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .clickBranco{
            svg{
                width: 40px;
                height: 46px;
                margin-left: 10px;
            }
        }

        h2{
            color: #00AFEF ;
            font-size: 20px;
            font-weight: 500;
            display: flex;
            margin-left: 20px;
        }
    }

    .logoIfollow{
        display: flex;
        width: 300px;
        margin: 15px auto 0px;
        padding-bottom: 20px;
        border-bottom: solid white 3px;
    }

    .textoIfollow{
        font-size: 16px;
        font-weight: 400;
        width: 260px;
        margin: 18px auto 0px;
        text-align: center;
    }

    .textoIfollow2{
        display: flex;
        flex-direction: column;
        width: 270px;
        text-align: end;
        font-size: 16px;
        font-weight: 500;

        h3{
            font-size: 16px;
            font-weight: 500;
            display: flex;
            justify-content: flex-end;
            color: #783C94;
        }

        strong{
            font-size: 16px;
            font-weight: 600;
            color: #783C94;
            margin-right: 1px;
        }
    }

    .linha1{
        display: flex;
        justify-content: end;
        width: 93% !important;
        background-color: #00AFEF;
        padding-right: 20px;
        margin-top: 100px;
        padding: 40px 30px 30px 0px;
        border-radius:  0px 0px 40px 0px;
        position: relative;

        .backgroundComputador{
            position: absolute;
            padding: 18px 40px 0px 30px;
            top: -80px;
            right: 0px;
            background-color: #00AFEF;
            border-radius: 80px 80px 0px 0px;
        }

        .computador{       
            width: 100px;
            height: 100px;                
            background: #00AFEF;
        }
    }

    .linha2{
        display: flex;
        justify-content: start;
        width: 93% !important;
        background-color: white;
        padding-left: 20px;
        margin-top: 58px;
        padding: 40px 0px 30px 30px;
        border-radius: 0px 0px 0px 40px;
        position: relative;
        color: #783C94 !important;
        margin-left: auto;

        h3{
            font-size: 16px;
            font-weight: 500;
            display: flex;
            justify-content: flex-end;
            color: #783C94;
            text-align: start;
            justify-content: flex-start;
        }

        strong{
            font-size: 16px;
            font-weight: 600;
            color: #783C94;
        }
    }

    .backgroundPasta{
        position: absolute;
        padding: 18px 40px 0px 30px;
        top: -80px;
        left: 0px;
        background-color: white;
        border-radius: 80px 80px 0px 0px;

        img{
            width: 100px;
            height: 100px;
        }
    }

    .aries{
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        width: 100%;

        .backgroundAries{
            width: 100%;
            min-height: 655px;
        }

        .logoAries{
            position: absolute;
            z-index: 9999;
            
            svg{
                width: 260px;
            }
        }

        .divTextoAries{
            position: absolute;
            z-index: 9999;
            width: 300px;
            min-width: 290px;
            padding: 60px 20px 38px;
            border-radius: 16px;
            background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
            top: 330px;

            h3{
                font-weight: 500;
                font-size: 14px;
                color: #124566;
                margin-bottom: 10px;
            }

            h2{
                font-size: 16px;
                color: #124566;
                margin-bottom: 3px;
            }

            .caminhao{
                padding: 60px 30px 0px 30px;
                border-radius: 200px 200px 0px 0px;
                position: absolute;
                top: -110px;
                left: 42px;

                svg{
                width: 170px;
                height: 95px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                }
            }

            .border-radius{
                padding: 60px 30px 0px 30px;
                border-radius: 200px 200px 0px 0px;
                background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
                position: absolute;
                top: -95px;
                left: 50px;
                width: 200px;
                height: 95px;
            }
        }
    }

    .ecotrade{
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        background-color: red;
        z-index: 9999;
        bottom: 0px;

    .backgroundEcotrade{
            width: 100%;
            width: 100%;
            min-height: 700px;
        }
    }

    .logoEcotrade{
        width: 265px;
        height: 150px;
        z-index: 9999;
        margin-top: 110px;
        position: absolute;
    }

    .textoEcotrade{
        padding: 30px;
        background-color: white;
        z-index: 9999;
        position: absolute;
        right: 0px;
        top: 400px;
        border-radius: 32px 0px 0px 32px;

        h3{
            width: 250px;
            color: #0A7E68;
            font-size: 16px;
            font-weight: 500;
        }

        strong{
            color: #0A7E68;
            font-size: 16px;
            font-weight: 600;
        }

        .margin25{
            margin-top: 25px;
        }

        h2{
            font-weight: 600;
            color: #0A7E68;
        }
    }

    .vesta{
        position: relative;
        width: 100%;

        .logoVesta{
            width: 100%;
            display: flex;
            position: absolute;
            top: 0;
            justify-content: center;
            svg{
            width: 250px;
            height: 150px;
            }
        }

        .textoVesta{
            width: 320px;
            position: absolute;
            bottom: 3px;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 60px 20px 20px 40px;
            border-radius: 0px 100px 0px 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3{
                font-size: 16px;
                font-weight: 500;
                color: white;
                width: 260px;
                margin: 20px auto 0px;
                color: #F15A24;
            }

            .bold{
                margin-top: 20px;
                h3{
                    margin-top: 0px !important;
                    font-weight: 600;
                }
            }

            h2{
                font-size: 20px;
                margin-bottom: 0px;
                width: 200px;       
                color: #F15A24;
            }
        }
    }

    .backgroundVesta{
        width: 100%;
        min-height: 600px;
    }

    .textoIfollow3{
        margin-top: 10px;
    }

    @media(min-width: 365px){
        .red{
            bottom:20px
        }

        .lista{
            h3{
                font-size: 14px; 
            }
        }
    }

    @media(min-width: 500px){
        .linha1{          
            strong{
                font-size: 20px;
            }

            h3{
                font-size: 20px;
            }

            .textoIfollow2{
                width: 90%;
                max-width: 550px;

                strong{
                    width: max-content;
                }
            }
        }
        
        .linha2{          
            strong{
                font-size: 20px;
            }

            h3{
                font-size: 20px;
            }

            .textoIfollow3{
                width: 90%;
                max-width: 550px;

                strong{
                    width: max-content;
                }
            }
        }
    }
`