import { ReactSVG } from "react-svg"
import { NossasSolucoesPageStyle } from "./style"
import LogoSit from "../../assets/logoSit.svg"
import Magic from "../../assets/magic.webp"
import ClickBranco from "../../assets/clickBranco.svg"
import Click from  "../../assets/click.svg"
import Maos from "../../assets/maos.svg"
import LogoIfollow from "../../assets/logoIfollow.webp"
import Computador from "../../assets/computador.svg"
import Pasta from "../../assets/pasta.svg"
import Aries from "../../assets/logoAries2.webp"
import LogoAries from "../../assets/logoAries.svg"
import Caminhao from "../../assets/caminhao.svg"
import Ecotrade from "../../assets/ecotrade.png"
import LogoEcotrade from "../../assets/logoEcotrade.png"
import LogoVesta from "../../assets/logoVesta.svg"
import Vesta from "../../assets/vesta.png"

export const NossasSolucoesPage = () => {
    return (<NossasSolucoesPageStyle>
        <div className="redWhite">
            <div className="magic">
                <img src={Magic}/>
                <a href="https://www.sittrade.com.br/" className="ancoraSit">
                    <h2>www.sittrade.com.br</h2>
                    <ReactSVG className="clickBranco" src={ClickBranco}/>  
                </a>
                <ReactSVG className="logoSit" src={LogoSit}/>
                <h4 className="textoLogoSit">SOLUÇÕES INTEGRADAS DE TRADE MARKETING</h4>
                <div className="divMid">
                    <h2>
                        Reunimos em um só lugar todas as expertises para atender sua 
                    </h2>
                    <h2>campanha de <h3>Trade Marketing:</h3></h2>
                </div>
                <div className="linhaBranca"></div>
                    <div className="lista">
                        <h2>- Planejamento</h2>
                        <h2>- Criação</h2>
                        <h2>- Produção</h2>
                        <h2>- Tecnologia</h2>
                        <h2>- Logística</h2>
                        <h3>+ um time com mais de 20 anos de experiência e o melhor prazo de entrega do mercado!</h3>
                    </div>
                    <a href={`https://wa.me/5511975759106`} className="red"> 
                        <h4>VAMOS CONVERSAR?</h4>
                        <ReactSVG className="maos" src={Maos}/>
                    </a>
            </div>
            </div>
            <div className="ifollow">
                <a href="https://www.ifollow.com.br" className="ancoraIfollow">
                    <h2>www.ifollow.com.br</h2>
                    <ReactSVG className="clickBranco" src={Click}/>
                </a>
                <img className="logoIfollow" src={LogoIfollow}/>
                <h2 className="textoIfollow">
                Somos experts em tecnologia voltada para o Trade Marketing e atuamos em duas frentes:
                </h2>
                <div className="linha1">
                <div className="textoIfollow2">
                    <div className="backgroundComputador">
                        <img className="computador" src={Computador}/>
                    </div>
                    <h3><strong>Digital Experience:</strong> elevamos a</h3> 
                    <h3>
                    visibilidade da sua marca através do uso de telas digitais, destacando o seu PDV dos concorrentes.
                    </h3>
                </div>
                </div>
                <div className="linha2">
                <div className="backgroundPasta">
                    <img className="pasta" src={Pasta}/>
                </div>
                <div className="textoIfollow3">
                    <h3><strong>iManage: </strong> nossa plataforma permite a</h3>
                    <h3>
                        otimização de suas campanhas de MPDV com inteligência, 
                        entregando gestão e visibilidade em tempo real de seus
                        ativos na palma da sua mão!
                    </h3>
                    </div>
                </div>
            </div>
            <div className="aries">
                <img className="backgroundAries" src={Aries}/>
                <ReactSVG className="logoAries" src={LogoAries}/>
                <div className="divTextoAries">
                    <div className="border-radius">
                    </div>
                    <ReactSVG className="caminhao" src={Caminhao}/>
                    <h3>Se você precisa de uma logística, nós somos
                        a escolha certa! 
                    </h3>
                    <h3>Atendemos todo o Brasil e 
                        te damos gestão e rastreamento de seus materiais em 
                        tempo real.</h3>
                    <h2>- Estoque</h2>
                    <h2>- Manuseio</h2>
                    <h2>- Transporte</h2>
                </div>
            </div>
            <div className="ecotrade">
                <img className="backgroundEcotrade" src={Ecotrade}/>
                <img className="logoEcotrade" src={LogoEcotrade}/>
                <div className="textoEcotrade">
                    <h3>Somos seu mais novo parceiro em impressões, e o mais importante de tudo: sustentáveis.</h3>
                    <h3 className="margin25">
                    Toda nossa infraestrutura foi pensada para <strong>alinhar eco e qualidade gráfica</strong>.
                    É hora de firmar um futuro diferente para o mercado.
                    </h3>
                </div>
            </div>
            <div className="vesta">
                <img className="backgroundVesta" src={Vesta}/>
                <ReactSVG className="logoVesta" src={LogoVesta}/>
                <div className="textoVesta">
                    <h2>E-PROCUREMENT E OUTSOURCING 4.0</h2>
                    <h3>Viemos para descomplicar e otimizar os processos de compras.</h3>
                    <div className="bold">
                        <h3>- Plataforma extremamente tecnológica</h3>
                        <h3>- Mesa especializada em compras</h3>
                        <h3>- Know-how humano</h3>
                    </div>
                    <h3>
                    É a união entre inteligência tecnológica e experiência humana da nossa equipe 
                    que nos faz o maior parceiro do seu time de Compras. Somos a revolução das Outsourcings.
                    </h3>
                </div>
            </div>
        </NossasSolucoesPageStyle>)
}