import { ReactSVG } from 'react-svg';
import TrioCirculoFundoBranco from '../../assets/trio-circulo-fundobranco.svg';
import { CasesContainer, CaseContainer } from './styles';
import LazyLoad from 'react-lazyload';

const Cases = () => {
    return (
        <CasesContainer>
            <div className='fixed-container'>
                <ReactSVG src={TrioCirculoFundoBranco} alt='círculos'/>
                <section>
                    <h1>Cases</h1>
                    <p>Nós temos a <b>solução</b> para o seu negócio!</p>
                </section>
            </div>
            <div className='cases'>
                <CaseContainer>
                    <div className='images'>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-isla01_11zon.webp' alt='case isla'/>
                        </LazyLoad>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-isla02_11zon.webp' alt='case isla'/>
                        </LazyLoad>
                    </div>
                    <div className='content'>
                        <h2>Isla</h2>
                        <p>
                            Em uma tarde de domingo, Ana Paula Padrão, apresentadora do programa Masterchef e forte personalidade no movimento feminino, 
                            reuniu grandes marcas em um evento feito exclusivamente para mulheres. 
                            A discussão sobre o empoderamento e o protagonismo feminino, que norteou o encontro, 
                            foi regada à Isla e seus três sabores de drinks – pêssego com maracujá; limão siciliano com gengibre e framboesa com amora.
                        </p>
                        <p>
                            A presença da marca no evento foi pensada em cada detalhe: as bebidas eram servidas diretamente do carrinho bike, 
                            desenvolvido especialmente para a marca, desde as cores à madeira utilizada no guarda-sol, 
                            em taças de acrílico que traziam o logo impresso. O uniforme do promotor também foi pensado na minúcia, 
                            ao ser feito de linho para trazer o ar de sofisticação e leveza que a marca tanto exala.
                        </p>
                    </div>
                </CaseContainer>
                <CaseContainer>
                    <div className='images'>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-guarana01_11zon.webp' alt='case guaraná'/>
                        </LazyLoad>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-guarana02_11zon.webp' alt='case guaraná'/>
                        </LazyLoad>
                    </div>
                    <div className='content'>
                        <h2>Ilha Guaraná</h2>
                        <p>
                            A criação da Ilha Guaraná tinha o desafio de mostrar em uma única peça 4 objetivos pedidos pelo cliente: 
                            reforçar os 100 anos de Guaraná Antarctica no mercado brasileiro; apresentar o portfólio completo da marca; 
                            mostrar a campanha publicitária do momento e explicitar o posicionamento de marca. 
                        </p>
                        <p>
                            Levamos em conta que grande parte do awareness da marca provinha de matérias de trade onde 
                            o shopper fosse imerso no universo Guaraná Antarctica, interagindo com o PDV, então, para contar sua história, 
                            o design-solução foi trazer na área lateral uma placa de PS que mostrasse os principais destaques da marca ao longo 
                            do seus 100 anos, além de 3 monitores de Digital Signage que trouxessem a evolução dos comerciais, 
                            datados dos anos 90 aos anos 2000, demonstrando também a evolução publicitária do Guaraná Antarctica. 
                        </p>
                        <p>
                            Na frente da ilha foi colocado um monitor com troca de conteúdo remota, para que a campanha fosse veiculada em mais um meio. 
                            Já no interior da ilha, foram colocadas prateleiras para exibir todos os SKUS da família Guaraná, 
                            incluindo o NATU, além de uma geladeira para que o shopper pudesse sair do PDV consumindo o produto.
                        </p>
                    </div>
                </CaseContainer>
                <CaseContainer>
                    <div className='images'>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-popcorners_11zon.webp' alt='case popcorners'/>
                        </LazyLoad>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-popcorners-1_11zon.webp' alt='case popcorners'/>
                        </LazyLoad>
                    </div>
                    <div className='content'>
                        <h2>Popcorners</h2>
                        <p>
                            O Popcorners já era conhecido e consumido nos Estados Unidos quando a Pepsico decidiu trazê-lo para o Brasil. 
                            A estratégia para inseri-lo no mercado foi, então, 
                            desenvolver um press kit para que alguns influenciadores de marca o testassem e desses seus feedbacks.
                        </p>
                        <p>
                            O kit precisava ter um perfil jovial, antenado e que fosse de total utilização no ambiente praiano e fora dele, 
                            para que o cliente pudesse levar a marca para todos os lugares. Assim, 
                            os itens desenvolvidos para atender à essa demanda foram uma sacola e uma bolsa térmica, 
                            ideias para uma ida à praia; uma caixa de som para animar todos os momentos, inclusive o banho; 
                            um kit para viagem para facilitar o armazenamento de objetos; uma garrafinha e uma fita salva-celular, que estava em alta no momento.
                        </p>
                        <p>
                            Cada kit contou ainda com os dois sabores de Popcorners que estavam sendo lançados no país: sal e queijo. 
                            Como resultado, a aceitação dos produtos foi tão boa, que a marca trouxe mais um sabor para o mercado brasileiro.
                        </p>
                    </div>
                </CaseContainer>
                <CaseContainer>
                    <div className='images'>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-pepsi_11zon.webp' alt='case pepsi'/>
                        </LazyLoad>
                        <LazyLoad>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/case-pepsi-1_11zon.webp' alt='case pepsi'/>
                        </LazyLoad>
                    </div>
                    <div className='content'>
                        <h2>Pepsi</h2>
                        <p>
                            A marca Pepsi tem uma presença muito forte no estado do Rio Grande do Sul, sendo muito admirada em Porto Alegre. Pensando nisso, 
                            desenvolvemos junto à marca uma ação especial para o aniversário de 250 anos da capital: 
                            um grupo de micro influenciadores locais foi selecionado para receber um press kit contendo uma lata de spray, 
                            canetinhas e alguns stencils, além de uma caixa de som personalizada por um grafiteiro paulista com o tema dos 250 anos de aniversário. 
                            O intuito era que os influenciadores customizassem a caixa do kit com uma arte que, para eles, representasse uma Porto Alegre perfeita, 
                            e postassem em suas redes no dia do aniversário da cidade, movimentando a campanha e reforçando os laços de Pepsi com a região.
                        </p>
                    </div>
                </CaseContainer>
            </div>
        </CasesContainer>
    );
};

export default Cases;