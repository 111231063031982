import styled from 'styled-components';

export const CasesContainer = styled.div`
    padding: 20px 0;
    width: 100%;
    max-width: 1580px;
    margin: 60px auto 0;

    > .fixed-container {
        position: fixed;
        top: 0;
        left: 5%;
        bottom: 0;
        margin: auto 0;

        display: flex;
        align-items: center;
        column-gap: 30px;
        z-index: -1;
        height: 500px;
    
        svg {
            height: 200px;
        }
    
        > section {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        
            > h1 {
                color: #D51B17;
                font-size: 5em;
                font-weight: 300;
                font-style: italic;
                line-height: 80px;
            }
        
            > p {
                color: #000;
                font-size: 1.2em;
                font-weight: 400;
                width: 60%;
                margin-left: 20px;
            }
        
            > p > b {
                color: #000;
                font-size: 1em;
                font-weight: 500;
            }
        }
    }

    > .cases {
        width: 70%;
        max-width: 900px;
        margin: 40px 0 0 30%;
        gap: 3px;

        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 50px;
    }

    @media screen and (max-width: 1320px) {
        > .fixed-container {
            > img {
                height: 180px;
            }

            > section {
                row-gap: 10px;
                > h1 {
                    font-size: 4em;
                }

                > p {
                    font-size: 1em;
                    margin-left: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 1070px) {
        > .fixed-container {
            > img {
                height: 160px;
            }

            > section {
                row-gap: 0px;
                > h1 {
                    font-size: 3em;
                }

                > p {
                    font-size: 0.9em;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        > .fixed-container {
            > img {
                height: 140px;
            }

            > section {
                > h1 {
                    font-size: 2.5em;
                    line-height: 50px;
                }

                > p {
                    font-size: 0.8em;
                }
            }
        }
    }

    @media screen and (max-width: 845px) {
        padding: 0px 5%;

        > .fixed-container {
            position: initial;
            margin: 100px auto 50px;
            height: 100px;
            width: 250px;
            column-gap: 10px;

            > img {
                height: 100px;
            }

            > section {
                row-gap: 10px;
                text-align: center;
                > h1 {
                    
                }

                > p {
                    width: 100%;
                }
            }
        }

        > .cases {
            width: 100%;
            margin: 0;
        }
    }

    @media screen and (max-width: 768px) {
        > .fixed-container {
            margin-top: 80px;
            > img {
                height: 120px;
            }

            > section {
                > h1 {
                    font-size: 5em;
                    line-height: 40px;
                }

                > p {
                    font-size: 2em;
                }
            }
        }
    }
`;

export const CaseContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;

    > .images {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        > div {
            width: 250px;
            height: 250px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
                height: 100%;
            }
        }
    }

    > .content {
        padding-right: 10px;
        > h2 {
            color: #000;
            font-size: 1em;
        }

        > p {
            color: #000;
            margin: 10px 0;
            font-size: 0.9em;
        }
    }

    @media screen and (max-width: 768px) {
        > .images {
            > figure {
                width: 160px;
                height: 160px;
            }
        }

        > .content {
            > h2 {
                font-size: 1.4em;
            }

            > p {
                font-size: 1em;
            }
        }
    }
`;