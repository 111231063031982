import { HeaderContainer } from './styles';
import SittradeLogo from '../../assets/logo-sit.svg';
import Menu from '../../assets/menu.svg';
import { useEffect, useState } from 'react';
import ModalHeaderNav from '../ModalHeaderNav';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const Header = () => {
    const [navModalOpened, setNavModalOpened] = useState(false);
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <ReactSVG 
                className='sit-svg'
                onClick={() => navigate('/')} 
                src={SittradeLogo} alt='logo'
            />
            <div className='menu-container' onClick={() => setNavModalOpened(true)}>
                <p>NAVEGUE</p>
                <ReactSVG 
                    className='menu-svg'
                    src={Menu} 
                    alt='menu'
                />
            </div>
            {navModalOpened && <ModalHeaderNav setCloseModal={setNavModalOpened}/>}
        </HeaderContainer>
    )
}

export default Header;