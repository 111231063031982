import { BodyContainer, HeaderContainer, ModalHeaderNavContainer } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import SittradeLogo from '../../assets/logo-sit.svg';
import InstagramBranco from '../../assets/instagram-branco.svg';
import LinkedinBranco from '../../assets/linkedin-branco.svg';
import Fechar from '../../assets/fechar.svg';
import TrioCirculoFundoPreto from '../../assets/trio-circulo-fundopreto.svg';
import { ReactSVG } from 'react-svg';

const ModalHeaderNav = ({setCloseModal}) => {
    const navigate = useNavigate();

    return (
        <ModalHeaderNavContainer>
            <HeaderContainer>
                <ReactSVG 
                    className='sit-svg'
                    onClick={() => navigate('/')} 
                    src={SittradeLogo} alt='logo'
                />
                <div className='menu-container' onClick={() => setCloseModal(false)}>
                    <p>FECHAR</p>
                    <ReactSVG className='fechar-svg' src={Fechar} alt='fechar'/>
                </div>
            </HeaderContainer>
            <BodyContainer>
                <div className='contato'>
                    <h1>Contato</h1>
                    <h2>Onde estamos</h2>
                    <p>Rua Jandiatuba, 630 – Conj. 430 Vila Andrade – São Paulo/SP</p>
                    <div>
                        <a 
                            target='_blank' 
                            rel="noreferrer" 
                            href="https://www.instagram.com/sittrademkt/"
                            aria-label="Visite o nosso Instagram"
                        >
                            <ReactSVG src={InstagramBranco} alt='instagram' />
                        </a>
                        <a 
                            target='_blank' 
                            rel="noreferrer" 
                            href="https://br.linkedin.com/company/sittrade"
                            aria-label="Visite o nosso Linkedin"
                        >
                            <ReactSVG src={LinkedinBranco} alt='Linkedin' />
                        </a>
                    </div>
                </div>
                <ReactSVG src={TrioCirculoFundoPreto} className='circulos'/>
                <nav>
                    <Link to='/' onClick={() => setCloseModal(false)}>Home</Link>
                    <a href='/#quem-somos' onClick={() => setCloseModal(false)}>Quem Somos</a>
                    <a href='/#oque-fazemos' onClick={() => setCloseModal(false)}>O que fazemos</a>
                    <Link to='cases' onClick={() => setCloseModal(false)}>Cases</Link>
                </nav>
            </BodyContainer>
        </ModalHeaderNavContainer>
    );
};

export default ModalHeaderNav;