import { CasesPageContainer } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Cases from '../../components/Cases';
import ReactGA from 'react-ga';
import {useEffect} from 'react';

const CasesPage = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <CasesPageContainer>
            <Header />
            <Cases />
            <Footer />
        </CasesPageContainer>
    );
};

export default CasesPage;