import { useEffect, useState } from 'react';
import { CasesContainer, ClientesContainer, HomeContainer, HomeTopoContainer, OqueFazemosContainer, QuemSomosContainer } from './styles';
import TrioCirculoFundoPreto from '../../assets/trio-circulo-fundopreto.svg';
import TrioCirculoFundoBranco from '../../assets/trio-circulo-fundobranco.svg';
import SetaBaixo from '../../assets/seta-baixo.svg';
import IconeCriacaoPlanejamento from '../../assets/icone-criação-planejamento.svg';
import IconeProducao from '../../assets/icone-produção.svg';
import IconeLogistica from '../../assets/icone-logistica.svg';
import IconeGestaoAcompanhamento from '../../assets/icone-gestão-acompanhamento.svg';
import Mapa from '../../assets/mapa.svg';
import { Link } from 'react-router-dom';
import LogoAmbev from '../../assets/logo-ambev.webp';
import LogoRedbull from '../../assets/logo-redbull.webp';
import LogoBurgerKing from '../../assets/logo-burgerking.webp';
import LogoaAjinomoto from '../../assets/logo-ajinomoto.webp';
import LogoBaxter from '../../assets/logo-baxter.webp';
import LogoMagalu from '../../assets/logo-magalu.webp';
import LogoMakro from '../../assets/logo-makro.webp';
import LogoMarfrig from '../../assets/logo-marfrig.webp';
import LogoMonster from '../../assets/logo-monster.webp';
import LogoMovida from '../../assets/logo-movida.webp';
import LogoPepsico from '../../assets/logo-pepsico.webp';
import LogoSwift from '../../assets/logo-swift.webp';
import LazyLoad from 'react-lazyload';
import { ReactSVG } from 'react-svg';

const Home = () => {
    const [carouselPage, setCarouselPage] = useState(1);
    const [timeOutId, setTimeOutId] = useState('');

    useEffect(() => {
        const imagesTimeOut = setTimeout(() => {
                switch (carouselPage) {
                    case 1:
                        return setCarouselPage(2);
                    case 2:
                        return setCarouselPage(3);
                    case 3:
                        return setCarouselPage(4);
                    case 4:
                        return setCarouselPage(1);
                    default:
                        return
                }
        }, 3500);
        setTimeOutId(imagesTimeOut);
    },[carouselPage]);

    return (
        <HomeContainer>
            <HomeTopoContainer>
                <div className='body'>
                    <ReactSVG src={TrioCirculoFundoPreto} alt='círculos'/>
                    <div className='frase-container'>
                        <p>soluções</p>
                        <p>integradas</p>
                        <p>em<strong>trade</strong></p>
                        <p>MKT</p>
                    </div>
                    <ReactSVG src={TrioCirculoFundoPreto} alt='círculos'/>
                </div>
                <a className='saiba-mais-container' href='/#quem-somos'>
                    <p>Conheça a <b>melhor solução</b> para o seu negócio</p>
                    <ReactSVG src={SetaBaixo}/>
                </a>
            </HomeTopoContainer>
            <QuemSomosContainer id='quem-somos'>
                <div className='description-container'>
                    <ReactSVG src={TrioCirculoFundoBranco}/>
                    <h1>
                        Quem
                        <span>somos?</span>
                    </h1>
                    <p>
                        Temos em nosso DNA a <b>experiência de mais de 20 anos na produção de materiais promocionais.</b> Desta forma, 
                        montamos uma equipe multidisciplinar e oferecemos um trabalho diferenciado no gerenciamento de criação, 
                        produção e distribuição destes materiais.           
                    </p>
                </div> 
                <p>Nossa equipe pode te ajudar na <b>solução completa ou em algumas etapas do processo.</b></p>
                <div className='images-container'>
                    <LazyLoad>
                        <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-sit-colorado-ativação.webp' alt='sit colorado'/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-sit-colorado-presskit_11zon.webp' alt='sit colorado presskit'/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-sit-mikes-budx-ativação.webp' alt='sit mikes budx'/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-sit-caminhão-adesivamento.webp' alt='sit caminhão adesivamento'/>
                    </LazyLoad>
                    <LazyLoad>
                        <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-sit-bodega-dante-robino-ativação.webp' alt='sit bodega dante robino'/>
                    </LazyLoad>
                </div>
            </QuemSomosContainer>
            <OqueFazemosContainer id='oque-fazemos'>
                <div className='oque-fazemos-container'>
                    <ReactSVG src={TrioCirculoFundoBranco}/>
                    <h1>
                        O que
                        <span>fazemos</span>
                    </h1>
                    <p>
                        O nosso principal objetivo é <b>agregar valor às marcas</b> que atendemos, 
                        com muita competitividade, confiabilidade, transparência e custos adequados.
                    </p>
                </div>
                <div className='itens-container'>
                    <div className='item'>
                        <h2>Criação e Planejamento</h2>
                        <div>
                            <ReactSVG src={IconeCriacaoPlanejamento} />
                            <p>
                                Temos uma equipe capacitada para auxiliar nossos clientes no momento de criação e briefing inicial do job. 
                                Nossa intenção é conseguir contribuir com o projeto desde o primeiro passo.
                            </p>
                        </div>
                    </div>
                    <div className='item'>
                        <h2>Produção</h2>
                        <div>
                            <ReactSVG src={IconeProducao} />
                            <p>
                                Nosso know-how permite oferecer ao cliente as opções mais interessantes, 
                                tanto em termos financeiros, quanto em inovação. 
                                Acompanhamos a produção do início ao fim.
                            </p>
                        </div>
                    </div>
                    <div className='item'>
                        <h2>Logística</h2>
                        <div>
                            <ReactSVG src={IconeLogistica} />
                            <p>
                                Atendemos todo o Brasil com excelência. Trabalhamos com modais rodoviários, 
                                dedicados e aéreos para garantir que o material seja entregue no prazo necessário.
                            </p>
                        </div>
                    </div>
                    <div className='item'>
                        <h2>Gestão e acompanhamento</h2>
                        <div>
                            <ReactSVG src={IconeGestaoAcompanhamento} />
                            <p>
                                Através da nossa ferramenta de gestão <a href='https://www.ifollow.com.br' target='_blank'>iFollow</a>, fechamos nossa cadeia de atendimento 360. 
                                O cliente possui visibilidade real time de todas as suas entregas e positivações.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='map-container'>
                    <ReactSVG src={Mapa} alt='mapa'/>
                    <section>
                        <h1>
                            Atendemos todo o Brasil com <b>agilidade <span>&</span> qualidade</b>
                        </h1>
                        <p>
                            Além da nossa estrutura própria em São Paulo, possuímos bases e parceiros nas principais capitais do país, 
                            garantindo uma grande capilaridade de produção e redução nos custos de distribuição de materiais. 
                        </p>
                    </section>
                </div>
            </OqueFazemosContainer>
            <CasesContainer>
                <h1>Cases</h1>  
                <p>Nós temos a <b>solução</b> para o seu negócio!</p>
                <Link to='/cases'>Confira aqui todos os nossos cases!</Link>
                <div className='cases'>
                    <ReactSVG src={TrioCirculoFundoBranco} />
                    <div className='images-container'>
                        <LazyLoad style={{gridArea: 'guarana'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-guarana_11zon.webp' alt='case guaraná'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'mikes'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-mikesw-ativação.webp' alt='case mikes'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'hoegaarden'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-hoegaarden_11zon.webp' alt='case hoegaarden'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'pepsi'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-pepsi_11zon.webp' alt='case pepsi'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'colorado'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-colorado_11zon.webp' alt='case colorado'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'vivant'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-vivant_11zon.webp' alt='case vivant'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'beats'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-beats_11zon.webp' alt='case beats'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'mikesk'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-mikes-kit_11zon.webp' alt='mikes kit'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'isla'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-isla_11zon.webp' alt='case isla'/>
                        </LazyLoad>
                        <LazyLoad style={{gridArea: 'bud'}}>
                            <img src='https://istockblobs.blob.core.windows.net/sitesittrade/home-case-budweiser-copa_11zon.webp' alt='case budweiser copa'/>
                        </LazyLoad>
                    </div>
                </div>
            </CasesContainer>
            <ClientesContainer carouselPage={carouselPage}>
                <ReactSVG src={TrioCirculoFundoPreto}/>
                <h1>
                    Alguns de nossos
                    <b>principais clientes</b>
                </h1>
                <div className='carousel'>
                    <div className='logos-container'>
                        <div className='images-container' >
                            <LazyLoad>
                                <img src={LogoAmbev} alt='ambev'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoBurgerKing} alt='burgerking'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoRedbull} alt='redbull'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoSwift} alt='swift'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoBaxter} alt='baxter'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoMagalu} alt='magalu'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoMakro} alt='makro'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoMarfrig} alt='marfrig'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoMonster} alt='monster'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoMovida} alt='movida'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoPepsico} alt='pepsico'/>
                            </LazyLoad>
                            <LazyLoad>
                                <img src={LogoaAjinomoto} alt='ajinomoto'/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className='buttons-container'>
                        <div 
                            style={{backgroundColor: carouselPage === 1 ? '#fff' : 'rgba(255, 255, 255, 0.52)'}} 
                            disabled={carouselPage === 1}
                            onClick={() => {
                                clearTimeout(timeOutId);
                                setCarouselPage(1);
                            }}
                        />
                        <div 
                            style={{backgroundColor: carouselPage === 2 ? '#fff' : 'rgba(255, 255, 255, 0.52)'}} 
                            disabled={carouselPage === 2}
                            onClick={() => {
                                clearTimeout(timeOutId);
                                setCarouselPage(2);
                            }}
                        />
                        <div 
                            style={{backgroundColor: carouselPage === 3 ? '#fff' : 'rgba(255, 255, 255, 0.52)'}} 
                            disabled={carouselPage === 3}
                            onClick={() => {
                                clearTimeout(timeOutId);
                                setCarouselPage(3);
                            }}
                        />
                        <div 
                            style={{backgroundColor: carouselPage === 4 ? '#fff' : 'rgba(255, 255, 255, 0.52)'}} 
                            disabled={carouselPage === 4}
                            onClick={() => {
                                clearTimeout(timeOutId);
                                setCarouselPage(4);
                            }}
                        />
                    </div>
                </div>
            </ClientesContainer>
        </HomeContainer>
    );
};

export default Home;