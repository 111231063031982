import Router from './routes';
import ReactGA from 'react-ga';
import {useEffect} from 'react';
ReactGA.initialize('G-QZ95643DF0')

const App = () => {
  function Analytics(){
    ReactGA.pageview('Pagina inicial')
  }

  useEffect(()=>{
    Analytics()
  },[])
  return (
   <Router />
  );
}

export default App;
